import React from 'react';
import { Container, Typography, CssBaseline, ThemeProvider, Grid } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", "Roboto", sans-serif',
  },
});

// Before the AboutUs component
interface SectionProps {
  title: string;
  text: string;
  image: string;
  imagePosition: 'left' | 'right';
}

const Section: React.FC<SectionProps> = ({ title, text, image, imagePosition }) => {
  const isImageOnLeft = imagePosition === 'left';

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: isImageOnLeft ? 'row' : 'row-reverse',
        alignItems: 'center',
        py: 5,
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography variant="h4" fontFamily="Poppins">
          {title}
        </Typography>
        <Typography fontFamily="Roboto" sx={{ mt: 2, fontSize: 18 }}>
          {text}
        </Typography>
      </div>
      <div style={{ flex: 1 }}>
        <img src={image} alt={title} style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    </Container>
  );
};


const SocialResponsibility: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Container>
        {/* Background Section */}
        <section
          style={{
            minHeight: '100vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h2" fontFamily="Poppins" color="black">
            Social Responsibility
          </Typography>
        </section>
        {/* Add more sections */}

{/* Interchanging Sections */}
<Grid container>
  <Grid item xs={12}>
    <Section
      title="Environmental, Social, and Governance"
      text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod urna ac felis lacinia, id scelerisque justo venenatis."
      image="path_to_image_1.jpg"
      imagePosition="left"
    />
  </Grid>
  <Grid item xs={12}>
    <Section
      title="People and Culture"
      text="Sed elementum, nisi eget mattis hendrerit, orci nisi laoreet sapien, non vestibulum purus orci id purus."
      image="path_to_image_2.jpg"
      imagePosition="right"
    />
  </Grid>

  <Grid item xs={12}>
    <Section
      title="Sustainability"
      text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod urna ac felis lacinia, id scelerisque justo venenatis."
      image="path_to_image_1.jpg"
      imagePosition="left"
    />
  </Grid>
  <Grid item xs={12}>
    <Section
      title="Safety"
      text="Sed elementum, nisi eget mattis hendrerit, orci nisi laoreet sapien, non vestibulum purus orci id purus."
      image="path_to_image_2.jpg"
      imagePosition="right"
    />
  </Grid>
  {/* Add more sections */}
</Grid>

      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default SocialResponsibility;
