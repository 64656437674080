// import React from 'react';
// import { Container, Typography, CssBaseline, ThemeProvider } from '@mui/material';
// import { createTheme } from '@mui/material/styles';
// import Header from '../components/Header';
// import Footer from '../components/Footer';

// // Define your MUI theme
// const theme = createTheme({
//   typography: {
//     fontFamily: '"Poppins", "Roboto", sans-serif',    
//   }
// });

// const TermsOfService: React.FC = () => {
//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Header />
//       <Container>
//         {/* Content for About Us */}
//         <Typography variant="h2" fontFamily="Poppins" sx={{ mt: 5 }}>
//         Terms Of Service        
//         </Typography>
//         <Typography fontFamily="Roboto" sx={{ mt: 2, fontSize: 18 }}>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod urna ac felis
//           lacinia, id scelerisque justo venenatis. Sed elementum, nisi eget mattis hendrerit, orci
//           nisi laoreet sapien, non vestibulum purus orci id purus.
//         </Typography>
//         {/* Add more content here */}
//       </Container>
//       <Footer />
//     </ThemeProvider>
//   );
// };

// export default TermsOfService;
// TermsOfService.tsx
import React from 'react';
import { Container, Typography, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", "Roboto", sans-serif',
  },
});

const TermsOfService: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Container>
      

      <Container sx={{mb:10, mt:10}}>
  <Typography variant="h2" fontFamily="Poppins" sx={{ mt: 5 }}>
    Terms of Service
  </Typography>
  <Typography fontFamily="Roboto" sx={{ mt: 2, fontSize: 18 }}>
    Welcome to SparkRide! Please read these terms carefully before using our services.
  </Typography>

  {/* Part 1: Acceptance of Terms */}
  <Typography variant="h5" fontFamily="Poppins" sx={{ mt: 4 }}>
    Part 1: Acceptance of Terms
  </Typography>
  <Typography fontFamily="Roboto" sx={{ mt: 2 }}>
    By using SparkRide, you agree to comply with and be bound by these terms.
  </Typography>

  {/* Part 2: User Responsibilities */}
  <Typography variant="h5" fontFamily="Poppins" sx={{ mt: 4 }}>
    Part 2: User Responsibilities
  </Typography>
  <Typography fontFamily="Roboto" sx={{ mt: 2 }}>
    - You are responsible for maintaining the security of your account.
    - You must not use our services for any illegal or unauthorized purpose.
    {/* Add more user responsibilities */}
  </Typography>

  {/* Part 3: Service Modifications */}
  <Typography variant="h5" fontFamily="Poppins" sx={{ mt: 4 }}>
    Part 3: Service Modifications
  </Typography>
  <Typography fontFamily="Roboto" sx={{ mt: 2 }}>
    We reserve the right to modify or terminate our services at any time.
    {/* Add more information about service modifications */}
  </Typography>

  {/* Part 4: Intellectual Property */}
  <Typography variant="h5" fontFamily="Poppins" sx={{ mt: 4 }}>
    Part 4: Intellectual Property
  </Typography>
  <Typography fontFamily="Roboto" sx={{ mt: 2 }}>
    The content and materials available on SparkRide are protected by intellectual property laws.
    {/* Add more information about intellectual property */}
  </Typography>

  {/* Part 5: Privacy Policy */}
  <Typography variant="h5" fontFamily="Poppins" sx={{ mt: 4 }}>
    Part 5: Privacy Policy
  </Typography>
  <Typography fontFamily="Roboto" sx={{ mt: 2 }}>
    Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your information.
  </Typography>

  {/* Part 6: Limitation of Liability */}
  <Typography variant="h5" fontFamily="Poppins" sx={{ mt: 4 }}>
    Part 6: Limitation of Liability
  </Typography>
  <Typography fontFamily="Roboto" sx={{ mt: 2 }}>
    We are not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services.
    {/* Add more information about limitation of liability */}
  </Typography>

  {/* Part 7: Governing Law */}
  <Typography variant="h5" fontFamily="Poppins" sx={{ mt: 4 }}>
    Part 7: Governing Law
  </Typography>
  <Typography fontFamily="Roboto" sx={{ mt: 2 }}>
    These terms are governed by and construed in accordance with the laws of your jurisdiction.
    {/* Add more information about governing law */}
  </Typography>

</Container>


        {/* Add sections and text here */}
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default TermsOfService;
